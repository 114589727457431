/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Progress,
  Col,
} from "reactstrap";

// core components

function SectionSkills() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const FrameTechList = (props) => {
    const items = props.items;
    const listItems = items.map((item) =>
    <Col className="mr-auto mt-3" md="3" sm="4">
      <Row>
        <Col 
          className="skill-img-col"
          md="12"
          style={{
            backgroundImage:
              "url(" + require("assets/img/frameworks/" + item.pic).default + ")",
            height: '150px',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
        >
        </Col>
      </Row>
      <p className="text-center">{ item.name }</p>
      <Progress
        max="100"
        value={ item.val }
        barClassName="progress-bar-success"
      />
    </Col>  
    );
    return (
      <Row>{listItems}</Row>
    );
  }
  const frameTech = [
    {
      name: 'React',
      pic: 'react_logo.png',
      val: "70"
    },
    {
      name: 'Next.js',
      pic: 'nextjs_logo.png',
      val: "65"
    },
    {
      name: 'Chakra UI',
      pic: 'chakraui_logo.png',
      val: "60"
    },
    {
      name: 'VueJS',
      pic: 'vuejs_logo.png',
      val: "65"
    },
    {
      name: 'Vanilla JavaScript',
      pic: 'js_logo.png',
      val: "44"
    },
    {
      name: 'NodeJS',
      pic: 'node_logo.png',
      val: "30"
    },
    {
      name: 'Firebase',
      pic: 'firebase_logo.png',
      val: "57"
    },
    {
      name: 'AngularJS',
      pic: 'angular_logo.png',
      val: "20"
    },
    {
      name: 'Sass',
      pic: 'sass_logo.png',
      val: "60"
    },
    {
      name: 'Python',
      pic: 'python_logo.png',
      val: "16"
    },
    {
      name: 'Django',
      pic: 'django_logo.png',
      val: "14"
    }
  ]
  const skills = [
    {
      name: 'GCP',
      pic: 'gcp_logo.png',
      val: "30"
    },
    {
      name: 'AWS',
      pic: 'aws_logo.png',
      val: "23"
    },
    {
      name: 'Azure',
      pic: 'azure_logo.png',
      val: "16"
    },
    {
      name: 'Github',
      pic: 'github_logo.png',
      val: "42"
    },
    {
      name: 'Gitlab',
      pic: 'gitlab_logo.png',
      val: "42"
    },
    {
      name: 'Illustrator',
      pic: 'illustrator_logo.png',
      val: "50"
    },
    {
      name: 'Photoshop',
      pic: 'photoshop_logo.png',
      val: "60"
    },
    {
      name: 'Idioma inglés',
      pic: 'eeuu_logo.png',
      val: "38"
    },
    {
      name: 'Memes',
      pic: 'memes_logo.png',
      val: "100"
    },
  ]
  return (
    <>
      <div id="skills" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title text-center">Skills & Tecnologías</h2>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="nav-tabs-navigation">
                <div className="nav-tabs-wrapper">
                  <Nav id="tabs" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <h5>Lenguajes & frameworks</h5>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "2" ? "active" : ""}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        <h5>Otros skills</h5>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <FrameTechList items={frameTech} />
                </TabPane>
                <TabPane tabId="2">
                  <FrameTechList items={skills} />
                </TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionSkills;
