import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function SectionAbout() {
  var birthDate = new Date('1987-7-17');
  var now = new Date();

  var yearAsMilliseconds = 31557600000;
  var diffInMillisenconds = now - birthDate;
  var diffInYears = diffInMillisenconds / yearAsMilliseconds;
  return (
    <>
      <div id="about" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title text-center">Sobre mí</h2>
              <p>
                <strong>¡Aprendí a programar!</strong> La historia es curiosa, porque comencé a estudiar <strong>Diseño Gráfico en la Universidad de Buenos Aires.</strong> Durante la carrera, me fuí dando cuenta de que si bien habían varias cosas que me apasionaban, en lineas generales no era para mi. Fué un momento bisagra en mi vida, porque había invertido años en adquirir un conocimiento que no se vió materializado en ningún título, lo cual representaba cierta frustración y una fuerte necesidad de cambio.
              </p>
              <p className="mt-4">
                Ahí fué cuando tuve la oportunidad de ingresar al mundo de la programación. En el que encontré un escenario donde podía desarrollar los aspectos que disfrutaba del diseño, <strong>mientras descubría otros placeres propios del desafío de pensar y llevar a cabo aplicaciones web.</strong>
              </p>
              <p className="mt-4">
                Durante este camino de ya unos siete años, conocí gente maravillosa, de la cual aprendí muchisimo y al día de hoy son parte de mi círculo de amistad. Por este motivo, es primordial para mí, sea cual sea el trabajo en el que esté, <strong>integrarme a un grupo de personas en las cuales apoyarme, aprender, crecer, ayudarlas en todo lo que pueda; y con suerte compartir otros aspectos de la vida.</strong>
              </p>
              <p className="mt-4">
                Hoy tengo {diffInYears.toFixed(0)} años. Me caracterizo por ser organizado y perfeccionista. Trato de pensar varios escenarios posibles y llevar a cabo el que más me parece viable a la hora de resolver determinada problemática. <strong>Soy una persona confiable, con mucho compromiso con el trabajo, pero siempre manteniendo la intención de tener tiempo libre para llevar una vida equilibrada.</strong>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionAbout;
