/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import { 
  Button,
  Col,
  Container,
  Row
} from "reactstrap";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

// core components

function SectionExp() {
  const now = new Date()
  const currentWorkDate = `2021.11 - ${now.getFullYear()}.${(now.getMonth() + 1) < 10 ? '0' + (now.getMonth() + 1) : (now.getMonth() + 1)}`
  return (
    <>
      <div id="exp" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title text-center">Experiencia laboral</h2>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <VerticalTimeline animate={ false }>
              <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date={currentWorkDate}
                  iconStyle={{ background: '#2B6CB0', color: '#fff' }}
                  contentStyle={{ background: '#2B6CB0', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #2B6CB0' }}
                >
                  <h3 className="vertical-timeline-element-title font-weight-bold">Frontend Developer</h3>
                  <h4 className="vertical-timeline-element-subtitle">Osana salud</h4>
                  <p>
                    Osana desarrolla infraestructura tecnológica para la industria de la salud. Su misión es facilitar el acceso a servicios de salud de calidad a través de la tecnología.
                    <br />
                    Desde que ingresé he trabajado en distintos proyectos, algunos ejemplos:
                    <ul className="mt-4">
                      <li>
                        El desarrollo de un portal integral de pacientes y médicos con herramientas de administración de dicha plataforma, que permita personalizar la experiencia de cada institución de una manera única según sus necesidades.
                        <br />
                        <label className="label label-info mr-1">JavaScript</label>
                        <label className="label label-info mr-1">React</label>
                        <label className="label label-info mr-1">Next.js</label>
                        <label className="label label-info mr-1">Chakra UI</label>
                      </li>
                      <li className="mt-4">
                        Formar parte del equipo encargado de desarrollar una herramienta para profesionales, en la cual se pueda brindar servicios a los pacientes, como atenderlos en una videoconsulta, expedirle recetas médicas, etc.
                        <br />
                        <label className="label label-info mr-1">JavaScript</label>
                        <label className="label label-info mr-1">React</label>
                        <label className="label label-info mr-1">Next.js</label>
                        <label className="label label-info mr-1">Chakra UI</label>
                      </li>
                      <li className="mt-4">
                        Formar parte del desarrollo de un dashboard en el que los profesionales de una institución médica puedan ver métricas sobre la performance que tuvieron en el mes de trabajo.
                        <br />
                        <label className="label label-info mr-1">VueJS</label>
                        <label className="label label-info mr-1">Quasar</label>
                      </li>
                    </ul>
                  </p>
                  <p className="text-center mt-3">
                    <Button
                      className="btn-round"
                      color="default"
                      type="button"
                      href="https://www.linkedin.com/company/osana-salud/"
                      target="_blank"
                    >
                      LinkedIn
                    </Button>
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2015.01 - 2021.11"
                  iconStyle={{ background: '#22543D', color: '#fff' }}
                  contentStyle={{ background: '#22543D', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #22543D' }}
                >
                  <h3 className="vertical-timeline-element-title font-weight-bold">Frontend Developer</h3>
                  <h4 className="vertical-timeline-element-subtitle">NubeliU / Logicalis</h4>
                  <p>
                    En NubeliU encaré muchos proyectos desafiantes, entre los que se destacan:
                    <ul className="mt-4">
                      <li>
                        El desarrollo de nuevas funcionalidades para el frontend de una plataforma open source de cloud computing llamada OpenStack.
                        <br />
                        <label className="label label-info mr-1">Python</label>
                        <label className="label label-info mr-1">Django</label>
                        <label className="label label-info mr-1">JavaScript</label>
                        <label className="label label-info mr-1">Angular</label>
                      </li>
                      <li className="mt-4">
                        Diseño y coding de clientes web para chatear con asistentes virtuales desarrollados utilizando DialogFlow.
                        <br />
                        <label className="label label-info mr-1">NodeJS</label>
                        <label className="label label-info mr-1">JavaScript</label>
                        <label className="label label-info mr-1">VueJS</label>
                        <label className="label label-info mr-1">DialogFlow</label>
                        <label className="label label-info mr-1">Firebase</label>
                        <label className="label label-info mr-1">GCP</label>
                        <label className="label label-info mr-1">AWS</label>
                      </li>
                      <li className="mt-4">
                        Participar activamente del nacimiento y crecimiento de una herramienta que permitía a los clientes de la empresa que migraban su infraestructura a la nube de GCP, AWS o Azure poder ver los costos de la misma, obtener reportes y generar políticas de tagging para ordenar esos costos de manera inteligente.
                        <br />
                        <label className="label label-info mr-1">NodeJS</label>
                        <label className="label label-info mr-1">JavaScript</label>
                        <label className="label label-info mr-1">VueJS</label>
                        <label className="label label-info mr-1">Firebase</label>
                        <label className="label label-info mr-1">GCP</label>
                        <label className="label label-info mr-1">AWS</label>
                        <label className="label label-info mr-1">Azure</label>
                      </li>
                      <li className="mt-4">
                        Desarrollar una aplicación web que permita llevar a cabo una videollamada utilizando de base Cisco Webex APIs, manejando los roles para restringir o permitir acciones a usuarios de acuerdo a sus privilegios.
                        <br />
                        <label className="label label-info mr-1">NodeJS</label>
                        <label className="label label-info mr-1">JavaScript</label>
                        <label className="label label-info mr-1">VueJS</label>
                        <label className="label label-info mr-1">Firebase</label>
                        <label className="label label-info mr-1">GCP</label>
                        <label className="label label-info mr-1">Cisco Webex</label>
                      </li>
                    </ul>
                  </p>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  date="2014.09 - 2015.12"
                  contentStyle={{ background: '#542863', color: '#fff' }}
                  contentArrowStyle={{ borderRight: '7px solid  #542863' }}
                  iconStyle={{ background: '#542863', color: '#fff' }}
                >
                  <h3 className="vertical-timeline-element-title font-weight-bold">Frontend Developer & Diseñador Gráfico</h3>
                  <h4 className="vertical-timeline-element-subtitle">The Inflatable Depot</h4>
                  <p>
                    Administré y generé nuevo contenido para la web de la compañía, la cual estaba montada sobre el eCommerce PrestaShop.
                    Además, confeccionaba newsletters que serían enviados a los clientes. 
                  </p>
                  <p className="text-center mt-3">
                    <Button
                      className="btn-round"
                      color="default"
                      type="button"
                      href="http://inflatabledepot.com/"
                      target="_blank"
                    >
                      website
                    </Button>
                  </p>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionExp;
