/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Button,
  Container,
  Row,
  Col,
  Card, CardText, CardBody, CardLink,
  CardTitle, CardSubtitle
} from "reactstrap";

// core components

function SectionChat() {
  var birthDate = new Date('1987-7-17');
  var now = new Date();

  var yearAsMilliseconds = 31557600000;
  var diffInMillisenconds = now - birthDate;
  var diffInYears = diffInMillisenconds / yearAsMilliseconds;
  return (
    <>
      <div id="hablemos" className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="8">
              <h2 className="title text-center">¡Hablemos!</h2>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto mr-auto" md="6">
              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <CardTitle tag="h5">Sebastián Gabriel Comisario</CardTitle>
                      <CardSubtitle tag="h6" className="mb-2 text-muted">Frontend developer | Argentino, {diffInYears.toFixed(0)} años</CardSubtitle>
                    </CardBody>
                    <img width="100%" src={require("assets/img/selfie_wide.jpg").default} alt="Selfie Sebastian Comisario" />
                    <CardBody>
                      <CardText>
                        <p>
                          Actualmente vivo en <strong>Villa Luro, CABA, Buenos Aires, Argentina.</strong> Si querés ponerte en contacto conmigo. Te dejo algunos medios de comunicación :)
                        </p>
                      </CardText>
                      <CardLink 
                        href="mailto:sebastian.comisario@gmail.com"
                        target="_blank"
                      >
                        <Button className="btn-round mr-1" color="danger" type="button">
                          email
                        </Button>
                      </CardLink>
                      <CardLink 
                        href="https://linkedin.com/in/scomisario"
                        target="_blank"
                      >
                        <Button className="btn-round mr-1" color="info" type="button">
                          LinkedIn
                        </Button>
                      </CardLink>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>{" "}
    </>
  );
}

export default SectionChat;
