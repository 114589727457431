/*!

=========================================================
* Paper Kit React - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-react

* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/paper-kit-react/blob/main/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";
// reactstrap components
import {
  // Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container>
        <div className="navbar-translate">
          <NavbarBrand
            data-placement="bottom"
            href="#root"
            title="Aprendí a programar"
          >
            <svg id="aprendi_logo_svg" data-name="Aprendi a programar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.16 50.38"><title>Aprendi a programar</title><polygon points="37.18 21.64 25.83 0 0 49.94 1.31 49.94 9.73 33.65 10.25 32.73 22.21 9.66 28.64 21.64 37.18 21.64"/><path d="M34.56,33.3l.16.17V33.3h0l.34.68,8.67,16.36h8.68l-9-17H34.56"/><polygon points="37.84 21.64 40.04 17.54 42.27 21.64 50.97 21.64 43.66 7.88 36.52 21.64 37.84 21.64"/><polygon points="30.78 32.97 21.91 50.05 23.23 50.05 27.56 41.53 28.08 40.61 32.09 32.97 30.78 32.97"/><path d="M48.35,33.3l3.94,7.38h0l.6,1.17,4.49,8.52h8.7L56.72,33.3Z"/><polyline points="65.92 11 57.01 11 57.05 21.81 65.75 21.81 65.92 11.46"/><polygon points="65.75 36.7 65.75 35.71 65.75 35.71 65.75 33.79 56.88 33.79 57.21 50.05 65.92 50.05 65.75 36.7 65.75 36.7"/><path d="M15.34,21a.71.71,0,0,1,.1.83h50V21Z"/><rect x="31.27" y="32.97" width="34.48" height="0.82"/><path d="M74.43,13l23.07,12L74.43,37.14l-.24.12c-.25.21.62,0,.32-.17l-1,.51L57.15,46.27l8.93,4.11,41.08-21.85L57.21,2.7V4l16.3,8.41"/></svg>
          </NavbarBrand>
          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="#about"
                title="Sobre mí"
              >
                <p>About</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="#exp"
                title="Experiencia"
              >
                <p>XP</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="#skills"
                title="Conocimientos"
              >
                <p>Skills</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="#hablemos"
                title="Hablemos"
              >
                <p>¡Hablemos!</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://github.com/scomisario"
                target="_blank"
                title="Mi GitHub"
              >
                <i className="fa fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://gitlab.com/scomisario"
                target="_blank"
                title="Mi Gitlab"
              >
                <i className="fa fa-gitlab" />
                <p className="d-lg-none">Gitlab</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://linkedin.com/in/scomisario"
                target="_blank"
                title="Mi LinkedIn"
              >
                <i className="fa fa-linkedin" />
                <p className="d-lg-none">LinkedIn</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                href="mailto:sebastian.comisario@gmail.com"
                target="_blank"
                title="Mi email"
              >
                <i className="fa fa-inbox" />
                <p className="d-lg-none">email</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
